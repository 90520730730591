.app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* position: absolute; */
}

g.recharts-scatter {
  clip-path: none !important;
}

.modal, .waiting-box, .loading-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  visibility: visible !important;
}

.loading-container {
  color: white;
  background: rgba(25,25,25,0.5)
}

.loading-text {
  display: flex;
}

.loading-ellipsis {
  min-width: 1.25em
}

.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

.modal-card {
  padding: 0 1rem 1rem 1rem;
}

.video-loading-box {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  /* padding: 1rem 2rem 0 2rem; */
  box-sizing: border-box;
  background: white;
}

.chart-body {
  position: relative;
  /* width: 90%; */
  width: 100%;
  height: 90%;
  /* min-height: ; */
  /* height: 58vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  /* margin: 2rem; */
  /* margin: 2rem 2rem 0 2rem; */
  /* padding: 1vh 4vw 1vh 0; */
  /* z-index: -2; */
}

.chart-container {
  position: relative;
  width: 100%;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  /* margin: 2rem; */
  /* margin: 2rem 2rem 0 2rem; */
  /* padding: 1vh 4vw 1vh 0; */
  /* z-index: -2; */
  /* overflow: hidden; */
}

.stats-paper {
  position: relative;
  left: -4px;
  /* padding: 4px; */
  /* padding: 0px 4px 0px 2px; */
  padding: 0 0 0 4px;
  height: 90%;
  width: auto;
  min-width: 20%;
  z-index: 0;
}

.chart-resp-header {
  position: relative;
  height: auto;
  min-height: 10%;
  max-height: 10%;
  /* height: 10%; */
  width: 100%;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-stats {
  width: 100%;
  height: 75% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  /* position: absolute; */
}

.chart-button-totals {
  position: relative;
  height: auto;
  min-height: 15%;
  max-height: 15%;
  /* height: 10%; */
  width: 100%;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 
        inset 0px 11px 8px -10px #CCC;
}

.resp-paper {
  position: relative;
  left: 4px;
  /* padding: 4px; */
  /* padding: 0px 0px 0px 4px; */
  padding: 0px 4px 0px 2px;
  height: 90%;
  width: auto;
  min-width: 20%;
  z-index: 0;
}

.chart-resp-header {
  position: relative;
  height: auto;
  min-height: 10%;
  max-height: 10%;
  /* height: 10%; */
  width: 100%;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-resp-buttons {
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* height: 100%; */
  height: auto;
  min-height: 90%;
  max-height: 90%;
  /* width: auto; */
  width: 100%;
  /* min-width: 150px;
  max-width: 300px; */
}

#graph-composed-chart {
  overflow: visible;
}

/* .chart-resp-buttons div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2px;
} */

.resp-list {
  overflow-x: hidden !important;
}

.chart-stats::-webkit-scrollbar-track, .resp-list::-webkit-scrollbar-track, .custom-scroll::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 6px;
}

.resp-list::-webkit-scrollbar, .chart-stats::-webkit-scrollbar, .custom-scroll::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

.resp-list::-webkit-scrollbar-thumb, .chart-stats::-webkit-scrollbar-thumb, .custom-scroll::-webkit-scrollbar-thumb {
	border-radius: 6px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #888;
}

.chart-header {
  display: flex;
  min-height: 10%;
  position: relative;
  margin: 0 2rem 0 2rem;
  overflow-x: auto;
}

/* .resp-list div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2px;
} */

.chart-surface {
  /* position: absolute; */
  width: 100%;
  /* height: 95%; */
  flex: 1;
  max-height: calc(100% - 25px);
}

.chart-paper {
  position: absolute;
  width: 100%;
  /* width: auto; */
  /* min-width: 60%; */
  /* min-width: 70vw;
  max-width: 100%; */
  /* min-height: 75vh; */
  height: 100%;
  display: flex;
  flex-flow: column;
  /* margin: 2rem; */
  /* margin: 2rem 2rem 0 2rem; */
  /* padding: 1vh 4vw 1vh 0; */
  z-index: 1;
  overflow: visible;
  /* background: black !important; */
}

.loading-box {
  position: absolute;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
}

.list-item-loading {
  height: 35px;
  width: 100%;
}

.chart-box {
  position: relative;
  width: auto;
  height: 100%;
  padding: 1vh 4vw 0 0;
  box-sizing: border-box;
}

.scatter {
  transform: translateY(53%);
}

.dial-card {
  width: 85%;
  height: 30%;
  /* min-height: 800px; */
  /* padding: 18px; */
  overflow: hidden;
}

.dial-row {
  /* border: 2px solid green; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  /* height: 90%; */
  height: 12vh;
  /* height: 50%; */
  margin: calc(16px + (26 - 16) * ((100vw - 300px) / (1600 - 300)));
  /* margin-bottom: calc(40px + (80 - 40) * ((100vw - 300px) / (1600 - 300)));; */
  margin-left: 4vw;
}

.dial-box {
  /* top: 0; */
  position: relative;
  /* border: 2px solid black; */
  height: 100%;
  /* width: 4.5%; */
  width: 100%;
  /* max-width: 30px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background:
        linear-gradient(transparent, transparent 49.5%, #aaa 50%, #aaa 50.1%, transparent 50.5%, transparent); */
}

.dial-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.dial-meter {
  position: relative;
  height: 100%;
  left: -3vw;
  font-size: calc(8px + (12 - 8) * ((100vw - 300px) / (1600 - 300)));
  transform: translateY(-1px);
}

.meter-top {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.meter-mid {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.meter-bottom {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.bg-grid-top {
  position: absolute;
  width: 100%;
  height: 50%;
  /* border-top: 1px dashed #aaa; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='0' x2='100%25' y2='0' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 9' stroke-dashoffset='3' /%3e%3cline x1='0' y1='100%25' x2='100%25' y2='100%25' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 9' stroke-dashoffset='0' /%3e%3c/svg%3e");
}

.bg-grid-bottom {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='100%25' x2='100%25' y2='100%25' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 9' stroke-dashoffset='0' /%3e%3c/svg%3e");
}

.circle-box {
  width: 100%;
  /* max-width: 20px; */
  max-width: 24px;
  filter: drop-shadow(0px 0px 2px #aaa);
  transition-property: transform, -webkit-transform;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  /* transition: transform 0.05s, -webkit-transform 0.05s; */
  will-change: transform;
}

.circle {
  position: relative;
  /* top: 50%; */
  width: 100%;
  height: 0;
  padding: 50% 0;
  /* margin: 1em auto; */
  border-radius: 50%;
  /* background: #81b980; */
  /* background: #81b749; */
  /* box-shadow: 1px 1px 12px 12px rgba(0,0,0,1); */
  /* background: #a7cd80; */
  /* -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%); */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* transition: top 0.15s;
  transition-timing-function: linear;
  will-change: transform; */
}

.circle-overlay {
  position: relative;
  width: 100%;
  height: 0;
  padding: 50% 0;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  will-change: background-color;
}

.tune-in-class {
  animation: tune-in 5s;
}

.tune-out-class {
  animation: tune-out 5s;
}

@keyframes tune-in {
  from {background-color: #3BB273;}
  to {background-color: transparent;}
}

@keyframes tune-out {
  from {background-color: #E15554;}
  to {background-color: transparent;}
}

@keyframes pull-down {
  from {transform: translateY(-110%);}
  to {transform: translateY(0%);}
}

.video-slider-box {
  position: absolute;
  bottom: 0;
  width: auto;
  min-width: 68%;
  /* height: 100%; */
  height: 28px;
  display: flex;
  justify-content: center;
  /* overflow: hidden; */
  /* animation: pull-down 1s; */
  /* transition-property: transform, -webkit-transform;
  transition-duration: 1s;
  transition-timing-function: linear; */
  transition: transform 1s, -webkit-transform 1s;
  will-change: transform;
}

.video-slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.dial-name {
  position: absolute;
  bottom: -6vh;
  /* padding-top: 30%; */
}

.average-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  transition: all ease 1s;
}

.average-button {
  /* margin: 1rem; */
  margin-bottom: 10px;
}

.average-button button {
  margin: 0 6px 0 6px;
}

.data-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.data-buttons button {
  margin: 0 8px 1rem 8px;
}